import { createRouter, createWebHistory } from "vue-router";
const routerHistorys = createWebHistory()

const router = createRouter({
    history: routerHistorys,
    routes: [
        {
            path: '/',
            component: () => import('@/components/PDFJS')
        },
        {
            path: '/HelloWorld',
            component: () => import('@/components/HelloWorld')
        },
        {
            path: '/CadViewerPDF',
            component: () => import('@/components/CadViewerPDF')
        },
        {
            path: '/CadViewer',
            component: () => import('@/components/CadViewer')
        },
        {
            path: '/CadViewerThreejsLoadSVG',
            component: () => import('@/components/CadViewerThreejsLoadSVG')
        },
        {
            path: '/CadViewerSVG',
            component: () => import('@/components/CadViewerSVG')
        },
        {
            path: '/PDFJS',
            component: () => import('@/components/PDFJS')
        },
        {
            path: '/PDFJS_Batch',
            component: () => import('@/components/PDFJS_Batch')
        }
    ]
})

export default router