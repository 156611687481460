import { createApp } from 'vue'
import App from './App.vue'
import router from './route/index'

const app = createApp(App)
app.use(router)
app.mount('#app')

import axios from 'axios'
import VueAxios from 'vue-axios'
app.use(VueAxios,axios)
// Axios.defaults.baseURL = '/api'

// var instance = axios.create({
//     //请求根目录
// 	baseURL: 'http://127.0.0.1:5000',     
// 	// withCredentials : true,
// 	//timeout: 1000,
// 	// headers: {'X-Custom-Header': 'foobar'},
// });


// app.prototype.$axios = instance;


