<template>
  <router-view />
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
html,body,#app{
  border:none;
  margin:0;
}
</style>
